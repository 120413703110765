body {
  font-family: $font-family-base;
  font-size: $font-size-body;
  line-height: $line-height-base;
  color: $body-color;
}
a {
  color: $link-color;
  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}
p {
  &:last-child {
    margin-bottom: 0;
  }
}
.extra-font {
  text-transform: uppercase;
}
* {
  outline: none !important;
}
.h1, .h2, .h3, .h5, .h6, h1, h2, h3, h5, h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  margin-bottom: $headings-margin-bottom;
  color: $headings-color;
}
.h1, h1 {
  font-size: $h1-font-size;
}
.h2, h2 {
  font-size: $h2-font-size;
}
.h3, h3 {
  font-size: $h3-font-size;
}
h4, .h4 {
  font-size: $h4-font-size;
  font-family: $font-family-base;
  color: $headings-color;
  font-weight: $headings-font-weight;
}
