#map {
  width: 100%;
  height: 550px;
  @include media-breakpoint-down(md) {
    height: 300px;
    margin-bottom: 50px;
  }
}
.contact-selector {
  font-size: 16px;
  vertical-align: middle;
  .jq-selectbox {
    vertical-align: middle;
    margin-left: 20px;
    width: 300px;
    font-size: 16px;
  }
}
