/*------------------------------------------------------------------
[Template Info]
Author:	LikeaPizza
Version:	1.0
Created:	6 September 2018
Last updated:	05 June 2018
Template:	Moravia
-------------------------------------------------------------------*/

/*-----------------------------------------------------------------
[Table of Contents]

  :: General styles
  :: Elements
  :: Helpers
  :: Top bar
  :: Site header
  :: Hero
  :: Sections
  :: Team
  :: Blog
  :: Contact Us
  :: Menu
  :: Forms & Buttons
  :: Comments
  :: Widgets
  :: Video and Images (Galleries)
  :: Events
  :: Popups
  :: Footer
-------------------------------------------------------------------*/
@import "theme_variables";

// Mixins
@import 'modules/_mixins';

/*************************
  General styles
*************************/
@import 'modules/_general';

/*************************
  Elements
*************************/
@import 'modules/_elements';

/*************************
  Helpers
*************************/
@import 'modules/_helpers';

/*************************
  Top bar
*************************/
@import 'modules/_topbar';

/*************************
  Site header
*************************/
@import 'modules/_site-header';

/*************************
  Hero
*************************/
@import 'modules/_hero';

/*************************
  Sections
*************************/
@import 'modules/_sections';

/*************************
  Team
*************************/
@import 'modules/_team';

/*************************
  Blog
*************************/
@import 'modules/_blog';

/*************************
  Contact Us
*************************/
@import 'modules/_contact-us';

/*************************
  Menu
*************************/
@import 'modules/_menu';

/*************************
  Forms & Buttons
*************************/
@import 'modules/_forms';

/*************************
  Comments
*************************/
@import 'modules/_comments';

/*************************
  Widgets
*************************/
@import 'modules/_widgets';

/*************************
  Video and Images (Galleries)
*************************/
@import 'modules/_gallery';

/*************************
  Events
*************************/
@import 'modules/_events';

/*************************
  Popups
*************************/
@import 'modules/_popup';

/*************************
  Footer
*************************/
@import 'modules/_footer';
