.red-label {
  color: #D0021B;
}
.green-label {
  color: #7ED321;
}
.menu-tabs {
  margin-top: 70px;
}
@include media-breakpoint-down(md) {
  .menu-tabs {
    margin-top: 25px;
  }
}
@include media-breakpoint-down(md) {
  .nav-tabs {
    margin-bottom: 45px;
  }
}
.menu-item {
  margin-bottom: 40px;
  @include media-breakpoint-up(md) {
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.table {
    margin-bottom: 60px;
  }
  @include media-breakpoint-down(md) {
    img {
      max-width: 300px;
      margin: 0 auto;
      display: block;
      margin-bottom: 20px;
    }
  }
  @include media-breakpoint-down(sm) {
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  p:last-child {
    margin-bottom: 0;
  }
  .large-menu-item__image {
    max-width: 330px;
  }
  .item-header {
    font-size: 24px;
    border-bottom: 1px #47559F dotted;
    padding-bottom: 7px;
    margin-bottom: 10px;
    &.table {
      border-bottom: 0;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 160px;
        height: 1px;
        border-bottom: 1px #47559F dotted;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 0;
      }
    }
  }
  .price {
    color: $primary-color;
    font-size: 21px;
  }
  h3 {
    color: $primary-color;
    i {
      margin-left: 10px;
    }
  }
  &.special {
    position: relative;
    margin-bottom: 70px;
    .content {
      border: 1px #D6D3E5 solid;
      padding: 30px 70px 35px;
    }
    .label {
      color: $primary-color;
      font-size: 20px;
      width: 220px;
      background: #fff;
      padding: 0 40px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: -12px;
    }
    p {
      color: $primary-color;
    }
  }
}
