.s-block {
  padding: 80px 0;
  &__team {
    padding: 3rem 0;
  }
  &.block__gray {
    background: #F6F6F6;
    color: #757577;
  }
  &.overlay {
    background: url('/images/placeholders/1920x1100.png') no-repeat center;
    background-size: cover;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 100%; height: 100%;
      top: 0; left: 0;
      background: RGBA(23, 17, 62, 0.7);
      z-index: 2;
    }
    * {
      position: relative;
      z-index: 3;
    }
    .content {
      background: #fff;
      padding: 50px;
      @include media-breakpoint-down(md) {
        padding: 20px;
      }
    }
  }
}
.s-block__subtitle {
  text-transform: uppercase;
  font-weight: bold;
  color: #7B7C81;
}
