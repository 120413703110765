.team-person {
  .team-photo {
    border-radius: 50%;
    overflow: hidden;
    width: 200px;
    height: 200px;
  }

  h4 {
    margin-bottom: 0;
  }

  .position {
    font-size: 17px;
  }
}

.team-person__bg {
  width: 100%;
  background: url('../images/placeholders/285x375.png') no-repeat center;
  background-size: cover;
  color: #fff;
  height: 375px;
  position: relative;
  padding: 20px;
  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    background: transparentize(#17113E, 0.5);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    transition: 0.25s;
  }
  .team_content {
    opacity: 0;
    position: relative;
    z-index: 2;
    transition: 0.25s;
  }
  &:hover {
    .team_content, &:before {
      opacity: 1;
    }
  }
  h4 {
    color: #fff;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
