.video-item {
  h3 {
    color: $body-color;
  }
  .image {
    position: relative;
    display: block;
    color: #fff;
    text-align: center;
    &:hover {
      .icon {
        opacity: 1;
      }
    }
  }
  .icon {
    width: 66px;
    height: 66px;
    position: absolute;
    background: #47559F;
    left: 50%;
    top: 50%;
    margin-left: -33px;
    margin-top: -33px;
    border-radius: 50%;
    text-align: center;
    line-height: 66px;
    opacity: 0.7;
    transition: 0.25s;
  }
}
.gallery_tiles-item {
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background: transparentize(#17113E, 0.5);
    z-index: 2;
  }
  i {
    position: absolute;
    font-size: 21px;
    z-index: 3;
    color: #fff;
    top: 25px;
    right: 25px;
  }
  .content {
    position: absolute;
    z-index: 3;
    padding: 20px;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  hr {
    width: 90px;
    margin: 15px auto;
    border: 1px #fff solid;
  }
  .content, i, &:before {
    opacity: 0;
    transition: 0.25s;
  }
  img {
     transition: all 0.3s;
  }
  &:hover {
    .content, i, &:before {
      opacity: 1;
    }
    img {
      transform: scale(1.1);
    }
  }
}
.container-gallery {
  .gallery_tiles-item {
    margin-bottom: 10px;
  }
}
