.event-item {
  .event-content {
    background: #fff;
    padding: 50px;
    @include media-breakpoint-down(md) {
      padding: 25px;
    }
  }
  .image {
    background: url('../images/placeholders/320x230.png') no-repeat center;
    background-size: cover;
    width: 100%;
    height: 100%;
    min-height: 150px;
  }
  .date {
    background: $primary;
    color: #fff;
    padding: 7px 25px;
  }
}
.event_block_date {
  position: absolute;
  font-family: $headings-font-family;
  font-size: 28px;
  background-color: transparentize($primary, 0.4);
  line-height: 1.2;
  padding: 15px 20px;
  right: 0;
  top: 0;
}
