.top-bar {
  color: #fff;
  background: $primary-color;
  padding: 15px 0;

  i {
    font-size: 20px;
    @include media-breakpoint-down(xs) {
      font-size: 16px;
    }
  }

  a {
    color: #fff;
    transition: 0.2s;

    &:hover {
      color: $link-color;
    }
  }
}
