.btn {
  font-size: 15px;
  text-transform: uppercase;
  box-shadow: 0 2px 3px 0 rgba(122,121,124,0.53);
  border: none;
  border-radius: $btn-border-radius;
  padding: $btn-padding-y $btn-padding-x;
  &.btn-primary {
    background: $primary;
    &:hover {
      background: $link-color;
    }
  }
  &.btn-light {
    background: $light;
    box-shadow: none;
    border: 1px #DADADA solid;
    padding: 3px 30px;
    text-transform: none;
  }
  &:focus, {
    box-shadow: none !important;
  }
}
.form-group {
  label {
    &.error {
      text-align: left;
      display: block;
      font-size: 12px;
      margin-top: 5px;
      color: red;
    }
  }
}
.form-control, .jq-selectbox__select {
  box-shadow: inset 0 2px 3px 0 rgba(0,0,0,0.1);
  border-color: $input-border-color;
  transition: $input-transition;
  &:focus {
    box-shadow: $input-focus-box-shadow;
    border-color: $input-focus-border-color;
  }
  &:-moz-placeholder {
    color: #9a9a9a;
  }
  &:-ms-input-placeholder {
    color: #9a9a9a;
  }
  &::-moz-placeholder {
    color: #9a9a9a;
  }
  &::-webkit-input-placeholder {
    color: #9a9a9a;
  }
}
.jq-selectbox__select {
  border: 1px $input-border-color solid;
  border-radius: .25rem;
  padding: .375rem .75rem;
  text-align: left;
  cursor: pointer;
  .jq-selectbox__trigger-arrow {
    top: 10px;
    right: 15px;
    position: absolute;
    width: 7px;
    height: 5px;
    right: 15px;
    top: 18px;
    &:after {
      content: "";
      width: 7px;
      left: 0;
      height: 5px;
      position: absolute;
      border: none;
      background: url(../images/menu-arrow.svg) no-repeat right center;
    }
  }
}
.opened {
  .jq-selectbox__trigger-arrow:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.jq-selectbox__dropdown {
  text-align: left;
  width: 100%;
  border: 1px $input-border-color solid;
  box-shadow: inset 0 2px 3px 0 rgba(0,0,0,0.1);
  padding: 10px 15px;
  border-radius: .25rem;
  background: #fff;
  ul {
    padding: 0;
    margin: 0;
    li {
      cursor: pointer;
      &:hover {
        color: $link-hover-color;
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .reservation-form {
    height: 320px;
  }
  .newsletter-form {
    height: 160px;
  }
}
