// Grid
$grid-gutter-width: 60px;

// Colors
$primary-color: #03012C;
$primary: $primary-color;
$secondary-color: #47559F;
$secondary: $secondary-color;
$light: #fff;
$dark: #9a9a9a;
$link-color: #47559F;
$link-hover-color: $primary;
$link-hover-decoration: none;
$headings-color: #000;

// Fonts
$font-family-base: 'Fauna One';
$font-size-body: 15px;
$font-weight-base: 400;
$line-height-base: 1.75;
$body-color: $dark;
$paragraph-margin-bottom: 1.3rem;

$form-family-extra: 'Abel';

// Headlines
$headings-font-family: 'Playfair Display';
$headings-font-weight: 400;
$h1-font-size: 48px;
$h2-font-size: 36px;
$h3-font-size: 28px;
$h4-font-size: 22px;
$headings-margin-bottom: 1rem;

// Navbar
$navbar-nav-link-padding-x: 13px;
$dropdown-border-radius: 0;
$dropdown-border-color: #EBEBEB;
$dropdown-padding-y: 0;
$dropdown-link-color: $primary-color;
$dropdown-item-padding-y: 10px;
$dropdown-min-width: 225px;
$dropdown-spacer: 0;

// Nav & tabs
$nav-tabs-link-active-color: $link-color;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: $primary-color;
$nav-tabs-border-width: 0;
$nav-link-padding-x: 0;
$nav-link-padding-y: 0.25rem;

// Forms & buttons
$btn-border-radius: 4px;
$btn-padding-y: 0.475rem;
$btn-padding-x: 2.35rem;
$input-border-color: #D4CFCF;
$input-focus-box-shadow: none;
$input-transition: border-color .25s ease-in-out, box-shadow .25s ease-in-out !default;
$input-focus-border-color: $input-border-color;

// Pagination
$pagination-color: #47559F;
$pagination-border-width: 0;
$pagination-active-bg: transparent;
$pagination-active-border-color: $primary-color;
$pagination-active-color: $pagination-color;
$pagination-padding-x: 0.3rem;
$pagination-hover-bg: transparent;
$pagination-line-height: 1;
$pagination-hover-color: $link-hover-color;
