.site-header {
  .navbar {
    position: relative;
  }
  @include media-breakpoint-down(lg) {
    .navbar {
      padding: 0;
      .navbar-collapse {
        margin-top: 20px;
      }
    }
  }
  .subscribe-link {
    position: absolute;
    right: 0;
    bottom: 10px;
  }
  @include media-breakpoint-down(lg) {
    .subscribe-link {
      position: static;
      font-size: 18px;
    }
  }
  .nav-item {
    margin-right: 0;
    font-size: 17px;
    position: relative;
    margin: 0 5px;
    .dropdown-toggle {
      padding-right: 25px;
      &:after {
        content: "";
        width: 7px;
        height: 5px;
        position: absolute;
        right: 10px;;
        border: none;
        top: 13px;
        background: url('../images/menu-arrow.svg') no-repeat right center;
        .full-width-page & {
          background-image: url('../images/menu-arrow-white.svg');
        }
        @include media-breakpoint-down(lg) {
          position: relative;
          top: 2px;
          margin-left: 10px;
          margin-right: -17px;
        }
      }
    }
    &.show {
      .dropdown-toggle:after {
        top: 13px;
        transform: rotate(180deg);
        @include media-breakpoint-down(lg) {
          top: 2px;
        }
      }
    }

    .nav-link {
      color: #46464A;
      transition: 0.2s;
      padding-top: 0;
      padding-bottom: 0;
      @include media-breakpoint-down(lg) {
        text-align: center;
        padding-right: 0;
        font-size: 20px;
        margin: 5px 0;
      }
      &:hover {
        text-decoration: none;
      }
      .full-width-page & {
        color: #fff;
      }
    }

    &.active .nav-link, &:hover .nav-link, &.show .nav-link {
      color: $link-hover-color;
      .full-width-page & {
        color: #fff;
        opacity: 0.85;
      }
    }
  }
  // Show menu on hover
  @include media-breakpoint-up(lg) {
    .dropdown {
      &:hover {
        .dropdown-menu {
          display: block;
        }
        .dropdown-toggle:after {
          top: 13px;
          transform: rotate(180deg);
        }
      }
      .dropdown-toggle {
        &:active {
          pointer-events: none;
        }
      }
    }
  }
  .dropdown-menu {
    border: 0;
    border-radius: $dropdown-border-radius;
    border-color: $dropdown-border-color;
    padding-top: 25px;
    margin: 0;
    padding-bottom: 0;
    .full-width-page & {
      background: transparent;
      left: 10px;
      padding-top: 10px;
    }
    @include media-breakpoint-down(lg) {
      .full-width-page & {
        padding-top: 0;
        left: 0;
      }
    }
    .dropdown-menu-container {
      @include media-breakpoint-up(lg) {
        border-top: 7px #47559F solid;
      }
      @include media-breakpoint-down(lg) {
        text-align: center;
      }
      .full-width-page & {
        border: none;
      }
    }
    .dropdown-item {
      text-transform: none;
      border-bottom: 1px #EBEBEB solid;

      &:active {
        background: #fff;
        color: $link-hover-color;
      }
      .full-width-page & {
        padding: 5px 0;
        border-color: RGBA(255, 255, 255, 0.2);
        transition: 0.25s;
        &:hover {
          background: transparent;
          color: #fff;
          opacity: 0.85;
        }
        &:active {
          background: transparent;
          color: #fff;
        }
      }
      &:last-child {
        border-bottom: 0;
      }
    }
    @include media-breakpoint-down(lg) {
      .dropdown-item {
        border-bottom: 0;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .dropdown-menu {
      padding-top: 0;
      .dropdown-menu-container {
        border-width: 3px;
      }
      .dropdown-item {
        padding: 10px 10px;
        font-size: 16px;
      }
    }
  }
  .navbar-toggler {
    padding: 0;
    margin-top: 5px;
    .navbar-toggler-icon {
      height: auto;
      font-size: 30px;
      color: $primary-color;
      &:before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f0c9";
      }
    }
  }
}
.full-width-page {
  position: relative;
  @include media-breakpoint-down(md) {
    background: url('../images/placeholders/1920x700.png') no-repeat center;
  }
  &:before {
    content: "";
    background: #03012C;
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    opacity: 0.5;
  }
  video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    background: url('../images/placeholders/1920x700.png') no-repeat;
    background-size: cover;
    transition: 1s opacity;
  }
  * {
    z-index: 10;
    position: relative;
  }
}
