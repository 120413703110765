img {
  max-width: 100%;
}
.link-unstyled {
  color: inherit;
}
.text-font {
  font-family: $font-family-base;
}
@include media-breakpoint-up(sm) {
  .curve-right {
    &:after {
      top: 0;
      right: -8px;
      content: "";
      position: absolute;
      width: 16px;
      height: 100%;
      background: url('../images/curve.svg') repeat-y center;
    }
  }
}

// Rows
.container {
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
}
.row {
  margin-left: ($grid-gutter-width / -2);
  margin-right: ($grid-gutter-width / -2);
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;
}
@include media-breakpoint-up(md) {
  .row-gap-100 {
    margin-left: -50px;
    margin-right: -50px;
    > [class*="col-"] {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  .row-gap-40 {
    margin-left: -20px;
    margin-right: -20px;
    > [class*="col-"] {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .row-gap-10 {
    margin-left: -5px;
    margin-right: -5px;
    > [class*="col-"] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
.row-gap-0 {
  margin-left: 0px;
  margin-right: 0px;
  > [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
}
.list-unstyled {
  li:before {
    display: none;
  }
}

// Half-block background
.half-block {
  position: relative;
  padding: 150px 0;
  @include media-breakpoint-up(md) {
    &:before {
      content: "";
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
      left: 0;
      background: url('../images/placeholders/935x650.png') no-repeat center;
      background-size: cover;
    }
  }
}

// Text colors
.text-light {
  color: $light;
}
.text-dark {
  color: $dark;
}
.white-links a {
  color: #fff;
}

// Animations
.fast {
  animation-duration: 0.6s;
  animation-delay: 0s;
}
.very-fast {
  animation-duration: 0.3s;
}
.slow {
  animation-duration: 0.3s;
}
