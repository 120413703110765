.search-form {
  button {
    border: 0;
    background: none;
    cursor: pointer;
  }
}
.widget {
  margin-bottom: 35px;
  .title {
    font-weight: 600;
    padding: 10px 15px;
    background: $primary-color
  }
  .content {
    h3 {
      margin-bottom: 15px;
    }
    padding: 20px 15px;
    background: #F6F6F6;
  }
}
.w-featured-post {
  img {
    width: 100%;
  }
}
.w-insta-feed {
  img {
    display: inline-block;
    margin-bottom: 1px;
    width: 32%;
    margin-right: 1px;
  }
}
.w-tags {
  a {
    border: 1px #979797 solid;
    display: inline-block;
    padding: 7px 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    color: $body-color;
    transition: 0.25s;
    &:hover {
      background: $body-color;
      color: #fff;
    }
  }
}
.w-categoried {
  li {
    padding: 10px 0;
    border-bottom: 1px $body-color dotted;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    a {
      color: $body-color;
      &:hover {
        color: $link-hover-color;
      }
    }
  }
}
