.white-popup-block {
  background: #fff;
  margin: 0 auto;
  max-width: 550px;
  padding: 35px 60px;
  color: $primary;
  position: relative;
  hr {
    width: 75px;
    height: 2px;
    background: $primary;
  }
  p {
    margin-bottom: 0;
  }
  .mfp-close {
    background: url('../images/close-icon.svg') no-repeat center;
    font-size: 0;
    color: transparent;
  }
}
