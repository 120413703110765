// Buttons
.btn-blue {
  background: #17b1cd;
  color: #fff;
  font-size: 21px;
  padding: 8px 38px;
  box-shadow: 0px 2px 1px 0px rgba(15, 129, 195, 1);
  &:hover {
    box-shadow: 0px 3px 1px 0px rgba(15, 129, 195, 0.8);
  }
}

// Timeline
.timeline {
  position: relative;
  // line
  &:before {
    content: "";
    top: 3px;
    position: absolute;
    width: 1px;
    height: 100%;
    background: #ccc;
    z-index: 2;
    left: 50%;
  }
  @include media-breakpoint-down(md) {
    &:before {
      display: none;
    }
  }
  .item {
    &.last {
      margin-top: 20px;
      padding-top: 30px;
      background: #ffffff;
      position: relative;
      z-index: 3;
    }
    &.start {
      margin-bottom: 20px;
    }
  }
  @include media-breakpoint-down(md) {
    .item {
      margin-bottom: 40px;
      p {
        margin-bottom: 0;
      }
    }
  }
  .start-el {
    width: 16px;
    height: 16px;
    background: #47559F;
    border: 1px #47559F;
    border-radius: 50%;
    position: relative;
    z-index: 3;
    &:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #47559F;
      position: absolute;
      top: -2px;
      left: -2px;
      animation: pulse 2s ease 0s infinite;
    }
  }
  .date {
    color: $primary-color;
    font-weight: 600;
    font-size: 22px;
    position: relative;
    background: #fff;
    z-index: 3;
    padding: 30px 10px;
  }
  @include media-breakpoint-down(md) {
    .date {
      padding: 10px;
    }
    img {
      margin-top: 20px;
    }
  }
}
@keyframes pulse {
  0% {
      opacity: 1;
      transform: scale(1);
  }
  80% {
      opacity: 0;
      transform: scale(2);
  }
  100% {
      opacity: 0;
      transform: scale(3);
  }
}
@keyframes beat {
	from {
		transform: scale(1);
		transform-origin: center center;
		animation-timing-function: ease-out;
	}
	10% {
		transform: scale(1.1);
		animation-timing-function: ease-in;
	}
	15% {
		transform: scale(1);
		animation-timing-function: ease-out;
	}
	25% {
		transform: scale(1.05);
		animation-timing-function: ease-in;
	}
  35% {
    transform: scale(1);
		animation-timing-function: ease-out;
  }
}

// Promo
.s-promo {
  padding: 70px 0;
  background: url('../images/placeholders/1920x700.png') no-repeat center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0; top: 0;
    background-color: RGBA(23, 17, 62, 0.7);
    z-index: 2;
  }
  > * {
    position: relative;
    z-index: 3;
  }
  .string-1 {
    font-size: 36px;
    font-family: $headings-font-family;
  }
  .string-2 {
    font-size: 150px;
    line-height: 1;
    font-family: $headings-font-family;
  }
  .string-3 {
    margin-top: 50px;
    font-size: 15px;
  }
}

// Breadcrumbs
.breadcrumbs {
  background: #F6F6F6;
  padding: 15px 0;
  color: #8F8F8F;
  .breadcrumb-item::before {
    display: none;
  }
  a {
    color: #8F8F8F;
    &:hover {
      color: $link-hover-color;
    }
  }
  i {
    font-size: 12px;
    margin: 0 5px;
  }
  .title {
    margin-right: 10px;
  }
}

// Tabs
.nav-tabs {
  border: 0;
  li {
    margin: 0 20px;
  }
  .nav-link {
    border: 0;
    border-bottom: 3px transparent solid;
    margin-bottom: 0;
    padding: 0.5rem 0;
    &:hover {
      border-color: transparent;
    }
    &.active {
      border-color: $link-hover-color;
      color: $link-color;
    }
  }
}
@include media-breakpoint-down(md) {
  .nav-tabs {
    li {
      margin: 0 10px;
    }
    .nav-link {
      font-size: 25px;
    }
  }

  .menu-item {
    h4 {
      font-size: 18px;
    }
  }
}

// Pagination
.page-item {
  margin: 0 5px;
  &.b {
    margin: 0 15px;
    a {
      padding: 4px 10px 0px 10px;
    }
  }
  .page-link {
    padding: .3rem .35rem;
    border: 0;
    background: #fff;
    color: $link-color;
    border-bottom: 2px transparent solid;
  }
  &.active {
    .page-link {
      background: #fff;
      color: $link-hover-color;
      border-color: $primary-color;
    }
  }
}

// Book a table
.book-a-table {
  height: 35px;
  line-height: 35px;
  color: #fff;
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;
  position: fixed;
  z-index: 5;
  bottom: 20px;
  right: 20px;
  transition: 0.25s;
  width: 43px;
  .btn-container {
    display: inline-block;
    width: 166px;
  }
  .icon {
    background: #47559F;
    padding: 0 15px;
    display: inline-block;
    float: left;
  }
  .label {
    padding: 0 15px;
    background: $primary-color;
    display: inline-block;
    float: left;
  }
  &:hover {
    color: #fff;
    opacity: 0.85;
    width: 166px;
  }
}

// GDPR
.gdpr-alert {
  display: none;
  box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.15);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: #fff;
}
