.comments-area {
  margin-top: 50px;
  ul {
    padding-left: 0;
    li {
      padding-left: 0;
    }
  }
  .children {
    margin-left: 80px;
  }
}
.comment {
  margin-bottom: 20px;
  .photo-container {
    margin-right: 20px;
    border-radius: 10px;
    overflow: hidden;
    width: 60px;
  }
  .content {
    p {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .reply {
    margin-top: 5px;
    width: 60px;
    a {
      color: $body-color;
      &:hover {
        color: $link-hover-color;
      }
    }
  }
}
