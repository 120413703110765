.site-footer {
  padding-top: 70px;
  background: $primary-color;

  .separator {
    border-right: 1px #47559F dotted;
  }

  .widget {
    max-width: 250px;
    margin: 0 auto;

    .title {
      font-weight: bold;
      font-size: 18px;
      position: relative;
      margin-bottom: 20px;

      &:after {
        content: "";
        width: 77px;
        height: 3px;
        background: #47559F;
        display: block;
        margin: 10px auto 0;
      }
    }

    i {
      font-size: 20px;
    }

    a {
      color: #fff;
    }
  }

  .copyright {
    background: #000;
    i {
      color: #47559F;
      margin: 0 5px;
    }
  }
}
footer .fa-heart {
  animation: beat 1s infinite;
}
