// Big quote
.quote {
  padding: 60px 0;
  position: relative;
  background: url('../images/figured-border.svg') repeat-x center top;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 16px;
    background: url('../images/figured-border.svg') repeat-x center top;
  }
  .title {
    color: $primary-color;
    font-size: 22px;
    letter-spacing: 4px;
  }
  blockquote {
    color: #000;
    padding: 0;
    border: 0;
    font-size: 24px;
    line-height: 1.55;
    font-style: normal;
    font-family: $headings-font-family;
  }
  cite, h4 {
    font-family: $font-family-base;
    font-style: normal;
    font-size: 18px;
    color: $dark;
  }
}

// Blog
.post {
  margin-bottom: 80px;
  &.single {

  }
  &:last-child {
    margin-bottom: 0;
  }
  .meta {
    a {
      color: $body-color;
    }
  }
  .post-preview {
    width: 100%;
    margin-bottom: 25px;
  }
}
@include media-breakpoint-down(md) {
  .post {
    margin-bottom: 50px;
  }
  .pagination {
    margin-bottom: 50px;
  }
}
.single-post blockquote {
  font-size: 22px;
  font-style: normal;
  border-left: 2px $secondary-color solid;
  padding: 10px 30px;
  margin: 30px 15px 30px 20px;

  p {
    margin: 0;
  }
}
.post {
  ul {
    list-style-type: none;
    padding-left: 22px;
    li {
      position: relative;
      padding-left: 16px;
      &:before {
        border-radius: 50%;
        content: "";
        width: 5px;
        height: 5px;
        position: absolute;
        left: 0;
        top: 7px;
        background: $secondary-color;
      }
    }
  }
}
table {
  border-collapse: collapse;
  border: 1px solid #D4CFCF;
  thead {
    background: #EBEBEB;
    font-weight: bold;
  }
  th, td {
    padding: 10px 45px;
    border: 1px solid #D4CFCF;
  }
  @include media-breakpoint-down(md) {
    th, td {
      padding: 10px 15px;
    }
  }
  tr:nth-child(even) {
    background: #F8F8F8;
  }
}
.share-this {
  margin: 60px 0;
  @include media-breakpoint-down(md) {
    margin: 30px 0;
  }
  .headline {
    font-size: 25px;
    margin-right: 30px;
    color: $primary-color;
    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
      font-size: 20px;
    }
  }
}
.social-likes {
  .social-likes__widget {
    display: inline-block;
    margin-left: 10px;
    background: red;
    padding: 5px 15px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.25s;
    &:hover {
      box-shadow: 0 2px 3px 0 rgba(122,121,124,0.53);
    }
    &.social-likes__widget_facebook {
      background: #3B5998;
    }
    &.social-likes__widget_pinterest {
      background: #D02029;
    }
    &.social-likes__widget_twitter {
      background: #54ACEF;
    }
    &.social-likes__widget_plusone {
      background: #E04939;
    }
  }
  @include media-breakpoint-down(md) {
    .social-likes__widget {
      margin-left: 0;
      margin-right: 10px;
    }
  }
}
.auhor-info {
  padding: 30px 0;
  border-top: 2px $secondary dotted;
  border-bottom: 2px $secondary dotted;
  .photo {
    border-radius: 20px;
    display: inline-block;
    overflow: hidden;
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.post-navigation {
  a {
    display: inline-block;
    vertical-align: middle;
  }
  .icon {
    vertical-align: middle;
    padding: 5px 10px;
    display: inline-block;
    position: relative;
    top: -3px;
    &.left {
      margin-right: 10px;
    }
    &.right {
      margin-left: 10px;
    }
  }
}
@include media-breakpoint-down(md) {
  .post-navigation {
    .icon {
      padding: 5px 5px;
    }
    a {
      font-size: 14px;
    }
  }
  .single-post blockquote {
    font-size: 18px;
    margin-left: 0;
  }
}
.related-posts {
  margin-top: 70px;
}
