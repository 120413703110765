.hero-section {
  padding: 180px 0;
  background: url('../images/placeholders/1920x700.png') no-repeat center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0; top: 0;
    background-color: RGBA(23, 17, 62, 0.6);
    z-index: 2;
  }
  > * {
    position: relative;
    z-index: 3;
  }
  .headline {
    font-size: 18px;
  }
}

// Slider
.hero-slider {
  .slide {
    padding: 220px 0;
    position: relative;
    background: url('../images/placeholders/1920x700.png') no-repeat center;
    background-size: cover;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0; top: 0;
      background-color: RGBA(23, 17, 62, 0.6);
      z-index: 2;
    }
    > * {
      position: relative;
      z-index: 3;
    }
    p:last-child {
      margin-bottom: 1rem;
    }
  }
  @include media-breakpoint-down(sm) {
    .slide {
      padding: 100px 0;
    }
  }
  &:not(.slick-initialized) {
    .slide {
      display: none;
      &:first-child {
        display: block;
      }
    }
  }
}
.slick-control {
  width: 25px;
  height: 46px;
  border: 0;
  position: absolute;
  top: 50%;
  margin-top: -23px;
  z-index: 15;
  cursor: pointer;
  &.slick-next {
    background: url('../images/slider-arrow-right.svg') no-repeat;
    right: 10%;
  }
  &.slick-prev {
    background: url('../images/slider-arrow-left.svg') no-repeat;
    left: 10%;
  }
}
.slick-dots {
  margin: 0;
  position: absolute;
  bottom: 50px;
  width: 100%;
  left: 0;
  li {
    cursor: pointer;
    display: inline-block;
    margin: 0 9px;
    opacity: 0.5;
    &.slick-active {
      opacity: 1;
    }
    button {
      cursor: pointer;
      font-size: 0;
      color: transarent;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      border: 0;
      background: #fff;
    }
  }
}
// slides height
.slick-track{
	display: flex;

	.slick-slide{
		display: flex;
		height: auto;
	}
}
